/*
 * Required for Material UI to work (icons)
 */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/*
  * Import the fonts you want to use
  */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* global SASS colors */
:root {
  --color-neutral-900: 15 19 22;
  --color-neutral-800: 31 37 43;
  --color-neutral-700: 51 62 72;
  --color-neutral-600: 83 97 110;
  --color-neutral-500: 121 131 141;
  --color-neutral-400: 171 177 181;
  --color-neutral-300: 213 216 218;
  --color-neutral-200: 234 235 236;
  --color-neutral-100: 244 245 245;
  --color-neutral-50: 249 250 250;

  --color-primary-900: 9 47 40;
  --color-primary-800: 4 68 53;
  --color-primary-700: 0 89 66;
  --color-primary-600: 0 119 72;
  --color-primary-500: 0 150 79;
  --color-primary-400: 51 171 114;
  --color-primary-300: 102 192 149;
  --color-primary-200: 153 213 185;
  --color-primary-100: 204 234 220;
  --color-primary-50: 235 247 241;

  --color-success-900: 9 47 40;
  --color-success-800: 4 68 53;
  --color-success-700: 0 89 66;
  --color-success-600: 0 119 72;
  --color-success-500: 0 150 79;
  --color-success-400: 51 171 114;
  --color-success-300: 102 192 149;
  --color-success-200: 153 213 185;
  --color-success-100: 204 234 220;
  --color-success-50: 235 247 241;

  --color-warning-900: 51 47 27;
  --color-warning-800: 99 75 23;
  --color-warning-700: 148 103 19;
  --color-warning-600: 196 130 15;
  --color-warning-500: 241 174 1;
  --color-warning-400: 251 191 36;
  --color-warning-300: 252 211 77;
  --color-warning-200: 253 230 138;
  --color-warning-100: 254 243 199;
  --color-warning-50: 255 251 235;

  --color-danger-900: 127 29 29;
  --color-danger-800: 153 27 27;
  --color-danger-700: 185 28 28;
  --color-danger-600: 220 38 38;
  --color-danger-500: 239 68 68;
  --color-danger-400: 248 113 113;
  --color-danger-300: 252 165 165;
  --color-danger-200: 254 202 202;
  --color-danger-100: 254 226 226;
  --color-danger-50: 254 242 242;

  --color-shades-0: 255 255 255;
  --color-shades-100: 0 0 0;

  --color-accent-orange-default: 239 96 36;
  --color-accent-orange-light: 253 236 229;
  --color-accent-orange-dark: 96 38 14;

  --color-accent-ruby-default: 197 17 98;
  --color-accent-ruby-light: 248 226 236;
  --color-accent-ruby-dark: 79 7 39;

  --color-accent-pink-default: 219 0 255;
  --color-accent-pink-light: 251 224 255;
  --color-accent-pink-dark: 88 0 102;

  --color-accent-violet-default: 143 0 255;
  --color-accent-violet-light: 242 224 255;
  --color-accent-violet-dark: 57 0 102;

  --color-accent-indigo-default: 74 0 233;
  --color-accent-indigo-light: 233 224 252;
  --color-accent-indigo-dark: 30 0 93;

  --color-accent-navy-default: 0 0 119;
  --color-accent-navy-light: 224 224 239;
  --color-accent-navy-dark: 0 0 48;

  --color-accent-blue-default: 0 97 203;
  --color-accent-blue-light: 224 236 249;
  --color-accent-blue-dark: 0 39 81;

  --color-accent-lime-default: 67 160 71;
  --color-accent-lime-light: 232 244 233;
  --color-accent-lime-dark: 27 64 28;

  --color-accent-forest-default: 10 96 73;
  --color-accent-forest-light: 226 236 233;
  --color-accent-forest-dark: 4 38 29;

  --color-accent-main: 208 221 40;

  /* global SASS typography */

  /* Important to note that static px values have been converted to rem */
  /* following the font property's shorthand to make the selection more plug and play */
  /* implementation should follow `font: [font-weight optional] [font-size] [font-family] */

  --typography-display1: 4.5rem;
  /* 72px */
  --typography-display2: 3.5rem;
  /* 56px */

  --typography-h1: 3rem;
  /* 48px */
  --typography-h2: 2.75rem;
  /* 44px */
  --typography-h3: 2.5rem;
  /* 40px */
  --typography-h4: 2rem;
  /* 32px */
  --typography-h5: 1.75rem;
  /* 28px */
  --typography-h6: 1.5rem;
  /* 24px */

  --typography-subHeader: 1.25rem;
  /* 20px */

  /* for the underline fonts, it's better practice to manually use the text-decoration property for your given component */
  --typography-p1: 1.125rem;
  /* 18px */
  --typography-p2: 1rem;
  /* 16px */
  --typography-p3: 0.875rem;
  /* 14px */

  --typography-caption: 0.75rem;
  /* 12px */
  --typography-footer: 0.625rem;
  /* 10px */
  --typography-fine-print: 0.5rem;
  /* 8px */

  /* global SASS box-shadows */

  --elevation-opacity: 0.15;
  --elevation-xs-shadow: 0px 1px 3px 1px rgba(0, 0, 0, var(--elevation-opacity));
  --elevation-s-shadow: 0px 2px 6px 2px rgba(0, 0, 0, var(--elevation-opacity));
  --elevation-m-shadow: 0px 4px 8px 3px rgba(0, 0, 0, var(--elevation-opacity));
  --elevation-l-shadow: 0px 6px 10px 4px rgba(0, 0, 0, var(--elevation-opacity));
  --elevation-xl-shadow: 0px 8px 12px 6px
    rgba(0, 0, 0, var(--elevation-opacity));

  /* global SASS screen breakpoints */
  /* these will be implemented as the min-width with the media query */
  /* based on tailwindcss recommendations */
  --layout-breakpoints-xl: 1440px;
  --layout-breakpoints-l: 1240px;
  --layout-breakpoints-m: 905px;
  --layout-breakpoints-s: 600px;
  --layout-breakpoints-xs: 475px;

  /* Global Font family  */
  --font-family-display: 'Poppins', sans-serif;
  --font-family-body: 'Roboto', sans-serif;
  --font-family-main: 'Roboto', sans-serif;

  /* global ButtonComponent configs */
  --button-outlined-outline-width: 1px;

  /* You can add global styles to this file, and also import other style files */
  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }

  /* A class is useful for applying styles to multiple elements without repeating the styles in your stylesheet.*/
  .hide-scrollbar {
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  /* You can add global styles to this file, and also import other style files */

  ad-table tbody td {
    max-width: 500px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  --topbar-height: 72px;
}

$text-display-classes: 'display1', 'display2';
$text-heading-classes: 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subHeader';
$text-small-classes: 'p1', 'p2', 'p3', 'caption', 'footer', 'fine-print';

@each $text-class in $text-display-classes {
  .text-#{$text-class} {
    &,
    &--semibold {
      font-family: var(--font-family-display);
      font-size: var(--typography-#{$text-class});
      line-height: 1.1;
      letter-spacing: -0.5px;
    }
    &--semibold {
      font-weight: 600;
    }
  }
}

@each $text-class in $text-heading-classes {
  .text-#{$text-class} {
    &,
    &--semibold {
      font-family: var(--font-family-display);
      font-size: var(--typography-#{$text-class});
    }
    &--semibold {
      font-weight: 600;
    }
    &--underline {
      text-decoration: underline;
    }
  }
}

@each $text-class in $text-small-classes {
  .text-#{$text-class} {
    &,
    &--underline,
    &--semibold {
      font-size: var(--typography-#{$text-class});
      letter-spacing: 0.25px;
    }
    &--semibold {
      font-weight: 500;
    }
    &--underline {
      text-decoration: underline;
    }
  }
}

.text-h1 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.16;
  }
}

.text-h2 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.27;
  }
}

.text-h3 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.2;
  }
}

.text-h4 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.25;
  }
}

.text-h5 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.28;
  }
}

.text-h6 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.33;
  }
}

.text-subHeader {
  &,
  &--underline,
  &--semibold {
    line-height: 1.2;
  }
}

.text-p1 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.6;
  }
}

.text-p2 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.5;
  }
}

.text-p3 {
  &,
  &--underline,
  &--semibold {
    line-height: 1.4;
  }
}

.text-caption {
  &,
  &--underline,
  &--semibold {
    line-height: 1.3;
  }
}

.text-footer {
  &,
  &--underline,
  &--semibold {
    line-height: normal;
  }
}

.text-fine-print {
  &,
  &--underline,
  &--semibold {
    line-height: normal;
  }
}
