:root {
  --ad-search-filter-font: 400 var(--typography-p3) var(--font-family-main);
  --ad-search-filter-placeholder-font: 400 var(--typography-p3)
    var(--font-family-main);

  --ad-chip-text-font: 400 var(--typography-p3) var(--font-family-main);
  --ad-chip-leading-icon-color: rgb(var(--color-neutral-700));
  --ad-chip-trailing-icon-color: rgb(var(--color-neutral-700));

  --ad-icon-svg-height: 24px;
  --ad-icon-svg-width: 24px;
  --ad-icon-svg-min-width: none;

  --ad-icon-fill-color: rgb(var(--color-neutral-400));
  --ad-icon-stroke-color: none;

  --ad-tooltip-width: 240px;
  --ad-tooltip-arrow-position: 50%;

  --ad-input-height: 40px;
}

@import './directives/input-field/input/ad-input.directive';
@import '../../styles-reset.scss';
